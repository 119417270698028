import { Lesson } from '../../api/endpoints/lesson';
import { db } from '../db';
import { LessonInDB, LessonInDBId } from './storage-lesson.type';

const checkLessonExists = async (lessonId: LessonInDBId) => {
  const lesson = await db.lessons.get(lessonId);
  if (!lesson) {
    throw new Error(`Lesson does not exist with id: ${lessonId}`);
  }
  return lesson;
};

export const lessonStorageService = {
  create: async (lesson: Lesson) => {
    const { id, slideIds } = lesson;

    const lessonToCreate: LessonInDB = {
      id,
      slideIds,
      selectedSlideId: slideIds[0],
      seenSlideIds: [slideIds[0]],
    };

    const offlineLesson = await db.lessons.get(id);

    if (!offlineLesson) {
      await db.lessons.add(lessonToCreate);
    }
  },

  readOne: async (id: LessonInDBId) => db.lessons.get(id),

  readMany: async () => db.lessons.toArray(),

  updateSelectedSlideId: async (
    id: LessonInDBId,
    selectedSlideId: LessonInDB['selectedSlideId'],
  ) => {
    await checkLessonExists(id);
    await db.lessons.update(id, { selectedSlideId });
  },

  addSeenSlideIds: async (
    id: LessonInDBId,
    seenSlideId: LessonInDB['seenSlideIds'][number],
  ) => {
    const lesson = await checkLessonExists(id);
    if (!lesson.seenSlideIds.includes(seenSlideId)) {
      await db.lessons.update(id, {
        seenSlideIds: [...lesson.seenSlideIds, seenSlideId],
      });
    }
  },
};
