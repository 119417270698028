import React, { useState } from 'react';
import * as MUI from '@mui/material';
import { Close } from '@mui/icons-material';
import { FEATURE_FLAGS, MAINTENANCE_INFO } from '../../constants';
import * as SC from './maintenance-dialog.style';
import { MAINTENANCE_MESSAGE_LOCAL_STORAGE_KEY } from './maintenance-dialog.constant';

export const MaintenanceDialog = () => {
  const { message, title, version } = MAINTENANCE_INFO;

  const [isMaintenanceDialogOpen, setIsMaintenanceDialogOpen] = useState(
    FEATURE_FLAGS.isMaintenanceMessageEnabled &&
      localStorage.getItem(MAINTENANCE_MESSAGE_LOCAL_STORAGE_KEY) !== version,
  );

  const handleDiscard = () => {
    setIsMaintenanceDialogOpen(false);
    localStorage.setItem(MAINTENANCE_MESSAGE_LOCAL_STORAGE_KEY, version);
  };

  if (!isMaintenanceDialogOpen) {
    return null;
  }

  return (
    <SC.MaintenanceDialog>
      <MUI.DialogTitle variant='h6'>{title}</MUI.DialogTitle>
      <MUI.DialogContent>
        <MUI.DialogContentText variant='body2' color='inherit'>
          {message}
        </MUI.DialogContentText>
      </MUI.DialogContent>
      <MUI.DialogActions className='actions'>
        <MUI.IconButton onClick={handleDiscard} color='inherit'>
          <Close />
        </MUI.IconButton>
      </MUI.DialogActions>
    </SC.MaintenanceDialog>
  );
};
