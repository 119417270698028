import { debounce } from 'lodash';
import React, { useState, Suspense, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Navigate,
  Routes,
  Outlet,
  Route,
} from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Backdrop, Typography, useTheme } from '@mui/material';
import './assets/styles/main.css';
import './assets/styles/index.css';
import { skipToken } from '@reduxjs/toolkit/query';
import { AxiosError } from 'axios';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import { InitialLoading } from './components/initial-loading';
import { CookiePopUp } from './components/common/cookie-pop-up';
import { useReadUserQuery } from './api/endpoints/user';
import { isUserLoggedIn } from './utils/is-user-logged-in';
import { useLiveCookie } from './hooks/use-live-cookie';
import { parseStringToBoolean } from './utils/parse-string-to-boolean';
import { clearPyodideFlags } from './utils/clear-pyodide-flags';
import { StripProvider } from './providers/stripe';
import { isInsideIframe } from './utils/is-inside-iframe';
import { isTheParentSameDomain } from './utils/is-the-parent-same-domain';
import { MaintenanceDialog } from './components/maintenance-dialog';
import { useAppTranslation } from './locales/i18n';

const CourseListings = React.lazy(
  () => import('./pages/course-listings/course-listings.component'),
);
const Login = React.lazy(() => import('./pages/login'));
const Register = React.lazy(() => import('./pages/register'));
const Logout = React.lazy(() => import('./pages/logout'));
const ErrorPage = React.lazy(() => import('./pages/error'));
const EditCourse = React.lazy(
  () => import('./pages/edit-course/edit-course.component'),
);
const Lesson = React.lazy(() => import('./pages/lesson/lesson'));
const AppVersion = React.lazy(() => import('./pages/app-version'));
const LessonsInCourse = React.lazy(
  () => import('./pages/lessons-in-course/lessons-in-course.component'),
);
const PasswordReset = React.lazy(
  () => import('./pages/password-reset/password-reset.component'),
);
const PasswordUpdate = React.lazy(
  () => import('./pages/password-update/password-update.component'),
);
const UserProfile = React.lazy(
  () => import('./pages/user-profile/user-profile.component'),
);
const PurchaseSuccess = React.lazy(
  () => import('./pages/purchase-success/purchase-success.component'),
);

const TeacherRoutes = () => {
  const { data: user } = useReadUserQuery();
  const isTeacher = user?.role === 'teacher';
  return isTeacher ? <Outlet /> : <Navigate to='/' />;
};

const PrivateRoutes = () => {
  const isLoggedIn = isUserLoggedIn();
  if (!isLoggedIn) {
    sessionStorage.setItem(
      'beforeLoginPath',
      window.location.pathname + window.location.search,
    );
  }
  return isLoggedIn ? <Outlet /> : <Navigate to='/login' />;
};

const RestrictedRoutes = () => {
  const [isLoggedIn] = useLiveCookie('isLoggedIn', parseStringToBoolean);

  const beforeLoginPath = sessionStorage.getItem('beforeLoginPath');

  if (isLoggedIn && beforeLoginPath) {
    sessionStorage.removeItem('beforeLoginPath');
    if (!beforeLoginPath.includes('logout'))
      return <Navigate to={beforeLoginPath} />;
  }
  return !isLoggedIn ? <Outlet /> : <Navigate to='/courses' />;
};

clearPyodideFlags();

export const App = () => {
  const theme = useTheme();
  const [isLoggedIn] = useLiveCookie('isLoggedIn', parseStringToBoolean);

  /**
   * Although, the response from the query below is never read in this component,
   * we still call it here to ensure the response is cached for any subsequent calls
   * to the same query by the children of this component. This allows us to show the
   * loading state only in one place (this component) and not in every place that we
   * call this query.
   */
  const { isLoading: isReadUserLoading, error } = useReadUserQuery(
    isLoggedIn ? undefined : skipToken,
    {
      // prevent caching of user data upon re-login to a different account
      refetchOnMountOrArgChange: true,
    },
  );
  const isLoading = isReadUserLoading;

  const searchParams = new URLSearchParams(window.location.search);
  const [isRecordMode] = useState<boolean>(
    searchParams.get('mode') === 'record',
  );
  const [isShowcaseMode] = useState<boolean>(
    searchParams.get('mode') === 'showcase',
  );
  const isSpecialMode = isRecordMode || isShowcaseMode;
  const [isDeviceSupported, setIsDeviceSupported] = useState(true);
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState<boolean>(
    document.cookie.indexOf('acceptedCookies') >= 0,
  );
  const removeNotification = useCallback(() => {
    sessionStorage.setItem('deviceSupportNotification', 'true');
    setIsDeviceSupported(true);
  }, []);

  const { t } = useAppTranslation();
  z.setErrorMap(
    makeZodI18nMap({
      t,
      handlePath: {
        ns: ['zod'],
      },
    }),
  );

  useEffect(() => {
    if (isRecordMode) {
      return undefined;
    }

    const checkDevice = () => {
      // Due to plausible loading earlier.
      setTimeout(() => {
        // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.plausible('screen_properties', {
          props: {
            screen_width: window.innerWidth,
            screen_height: window.innerHeight,
            has_fine_pointer: window.matchMedia('(any-pointer: fine)').matches,
          },
        });
      }, 1000);

      if (window.innerWidth <= theme.breakpoints.values.md) {
        setIsDeviceSupported(false);
        return;
      }

      setIsDeviceSupported(true);
    };

    // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    window.plausible =
      window.plausible ||
      // NOTE: the use of `arguments` on the function body requires us to use a function declaration here
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function plausibleFallback() {
        // FIXME (478, 486): resolve the ESLint error and remove the eslint-disable comment
        // eslint-disable-next-line prefer-rest-params, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
        (window.plausible.q = window.plausible.q || []).push(arguments);
      };

    const debouncedCheckDevice = debounce(checkDevice, 200);
    const pointerCheck = window.matchMedia('(any-pointer: fine)');
    debouncedCheckDevice();
    window.addEventListener('resize', debouncedCheckDevice);
    pointerCheck.addEventListener('change', debouncedCheckDevice);

    return () => {
      window.removeEventListener('resize', debouncedCheckDevice);
      pointerCheck.removeEventListener('change', debouncedCheckDevice);
    };
  }, [theme.breakpoints.values.md, isRecordMode]);

  if (isInsideIframe() && !isTheParentSameDomain()) {
    return (
      <Typography>This content cannot be displayed in an iframe.</Typography>
    );
  }

  if (
    isLoading ||
    (error instanceof AxiosError && error.response?.status === 429)
  ) {
    return <InitialLoading />;
  }

  return (
    <StripProvider>
      <DndProvider backend={HTML5Backend}>
        <Helmet>
          {process.env.REACT_APP_PLAUSIBLE_HOST && (
            <script
              defer
              data-domain={process.env.REACT_APP_PLAUSIBLE_HOST}
              src='https://plausible.auditorium.ai/js/script.js'
            />
          )}
          <script>
            {`window.plausible = window.plausible ||
						function () {
							(window.plausible.q = window.plausible.q || []).push(arguments);
						};`}
          </script>
        </Helmet>
        {!isSpecialMode && (
          <CookiePopUp
            hasAcceptedCookies={hasAcceptedCookies}
            setHasAcceptedCookies={setHasAcceptedCookies}
          />
        )}
        <MaintenanceDialog />
        <Backdrop
          style={{
            color: '',
            zIndex: 1000000,
            padding: '4rem',
            backgroundColor: '#1C4647',
            flexDirection: 'column',
          }}
          open={
            !isDeviceSupported &&
            !isSpecialMode &&
            !sessionStorage.getItem('deviceSupportNotification')
          }
          onClick={removeNotification}
          onTouchEnd={removeNotification}
        >
          <Typography textAlign='center' color={theme.palette.common.white}>
            To get better experience please use a device with a larger screen.
          </Typography>
          <Typography
            marginTop='0.5rem'
            fontSize='0.75rem'
            textAlign='center'
            color={theme.palette.common.white}
          >
            Tap to dismiss
          </Typography>
        </Backdrop>
        <Suspense fallback={<InitialLoading />}>
          <BrowserRouter>
            <Routes>
              <Route path='/courses' element={<CourseListings />} />
              <Route path='/' element={<Navigate to='/login' />} />
              <Route path='/home' element={<Navigate to='/login' />} />
              <Route path='/*' element={<ErrorPage />} />
              <Route path='/courses/:courseId' element={<LessonsInCourse />} />
              <Route path='/lesson/:courseId'>
                <Route path='' element={<Lesson />} />
                <Route path=':lessonId' element={<Lesson />} />
              </Route>
              <Route
                path='/courses/:courseId/purchaseSuccess'
                element={<PurchaseSuccess />}
              />
              <Route element={<PrivateRoutes />}>
                <Route path='/profile' element={<UserProfile />} />
                <Route path='/logout' element={<Logout />} />
                <Route element={<TeacherRoutes />}>
                  <Route path='/courses/edit'>
                    <Route path=':courseId' element={<EditCourse />} />
                    <Route path='' element={<EditCourse />} />
                  </Route>
                </Route>
              </Route>
              <Route element={<RestrictedRoutes />}>
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/passwordReset' element={<PasswordReset />} />
                <Route
                  path='/passwordUpdate/:token'
                  element={<PasswordUpdate />}
                />
              </Route>
              <Route path='/version' element={<AppVersion />} />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </DndProvider>
    </StripProvider>
  );
};
