import Bugsnag from '@bugsnag/browser';
import { enqueueSnackbar } from '../../utils/snackbar-helper';
import { STORAGE_USE_ERROR_MESSAGE } from '../storage-api.constant';
import { CourseStorageControllers } from './storage-course.type';
import { courseStorageService } from './storage-course.service';

export const courseStorageControllers: CourseStorageControllers = {
  '/courses/:courseId/currentLesson': async ({
    params: { courseId },
    payload: { lessonId },
  }) => {
    try {
      await courseStorageService.updateSelectedLessonId(courseId, lessonId);
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });
    }
  },
};
