import { api } from '../../api';
import { API_TAGS } from '../../api-tags';
import {
  ReadUserResponse,
  UpdateUserQueryArgs,
  UpdateUserResponse,
} from './api-user.type';

export const { useReadUserQuery, useUpdateUserMutation } = api.injectEndpoints({
  endpoints: (builder) => ({
    readUser: builder.query<ReadUserResponse, void>({
      query: () => ({
        url: '/user',
        method: 'get',
      }),
      providesTags: (result) => (result ? [API_TAGS.user(result.id)] : []),
    }),

    updateUser: builder.mutation<UpdateUserResponse, UpdateUserQueryArgs>({
      query: ({ params, payload }) => ({
        url: `/users/${params.id}`,
        method: 'patch',
        payload,
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.user(params.id)],
    }),
  }),
});
