import { useSnackbar, VariantType, WithSnackbarProps } from 'notistack';

let useSnackbarRef: WithSnackbarProps;

const enqueueSnackbar: (
  message: string,
  options?: { variant: VariantType },
) => void = (message, options) => {
  if (!useSnackbarRef) {
    throw new Error(
      'SnackbarUtilsConfigurator is not rendered for enqueueSnackbar to be available',
    );
  }

  useSnackbarRef.enqueueSnackbar(message, options);
};

const closeSnackbar: (key?: string | number) => void = (key) => {
  if (!useSnackbarRef) {
    throw new Error(
      'SnackbarUtilsConfigurator is not rendered for closeSnackbar to be available',
    );
  }

  useSnackbarRef.closeSnackbar(key);
};

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

export { enqueueSnackbar, closeSnackbar };
