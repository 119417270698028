// FIXME (478): resolve the ESLint error and remove the eslint-disable comment
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getResponseErrorMessage = (e: any): string =>
  // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
  (e?.response?.data?.errors?.length > 0 &&
    // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    e?.response?.data?.errors[0]?.msg) ||
  // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  e?.response?.data?.error ||
  // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  e?.response?.data?.message ||
  // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  e?.response?.data?.info ||
  // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  e?.message ||
  // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  e?.toString() ||
  'Something went wrong!';
