import Bugsnag from '@bugsnag/browser';
import { enqueueSnackbar } from '../../utils/snackbar-helper';
import { STORAGE_USE_ERROR_MESSAGE } from '../storage-api.constant';
import { CodeSubmissionStorageControllers } from './storage-code-submission.type';
import { codeSubmissionStorageService } from './storage-code-submission.service';

export const codeSubmissionStorageControllers: CodeSubmissionStorageControllers =
  {
    '/codeSubmissions': async ({ payload }) => {
      try {
        await codeSubmissionStorageService.create({ payload });
        return { success: true };
      } catch (error) {
        if (error instanceof Error) {
          Bugsnag.notify(error);
        }
        enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });
        throw error;
      }
    },
  };
