import Bugsnag from '@bugsnag/browser';
import {
  ElementValueCodeEditor,
  ElementValueFrontendCodeEditor,
} from '../../api/endpoints/element';
import { enqueueSnackbar } from '../../utils/snackbar-helper';
import { STORAGE_USE_ERROR_MESSAGE } from '../storage-api.constant';
import { ElementStorageControllers } from './storage-element.type';
import { elementStorageService } from './storage-element.service';

export const elementStorageControllers: ElementStorageControllers = {
  '/users/slideElement/:id': async ({ params: { id }, payload: { value } }) => {
    try {
      await elementStorageService.updateValue(
        id,
        value as ElementValueCodeEditor | ElementValueFrontendCodeEditor,
      );
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });
    }
  },
};
