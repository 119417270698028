import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IActiveState } from '../state';

const initialState: IActiveState = {};

const activeSlice = createSlice({
  name: 'slides',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setActiveCourse: (state, action: PayloadAction<string | undefined>) => {
      state.activeCourseId = action.payload;
    },
    setSettingsOpenElementId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.settingsOpenElementId = action.payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { setActiveCourse, setSettingsOpenElementId } =
  activeSlice.actions;

export const { reducer: activeReducer } = activeSlice;
