import { db } from '../db';
import {
  ElementInDB,
  ElementInDBId,
  ElementInDBWhereClause,
} from './storage-element.type';

const checkElementExists = async (elementId: ElementInDBId) => {
  const element = await db.elements.get(elementId);
  if (!element) {
    throw new Error(`Element does not exist with id: ${elementId}`);
  }
  return element;
};

export const elementStorageService = {
  create: async (element: ElementInDB) => {
    const offlineElement = await db.elements.get(element.id);

    if (offlineElement) {
      return null;
    }

    // the switch-case is only to satisfy the type checker
    switch (element.type) {
      case 'code': {
        return db.elements.add(element);
      }
      case 'frontendCode': {
        return db.elements.add(element);
      }
      default:
        // justified to disable the eslint rule (and expect ts-error) here
        // because `type` cannot be any type other than `never`
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        throw new Error(`Invalid element type: ${element.type}`);
    }
  },

  readOne: async (id: ElementInDBId) => db.elements.get(id),

  readMany: async (whereClause?: ElementInDBWhereClause) => {
    if (!whereClause) {
      return db.elements.toArray();
    }

    return db.elements.where(whereClause).toArray();
  },

  updateValue: async (id: ElementInDBId, value: ElementInDB['value']) => {
    const element = await checkElementExists(id);
    await db.elements.update(id, {
      value: {
        ...element.value,
        ...value,
      },
    });
  },

  updateIsAssignmentPassed: async (
    id: ElementInDBId,
    isAssignmentPassed: boolean,
  ) => {
    await checkElementExists(id);
    await db.elements.update(id, {
      isAssignmentPassed,
    });
  },
};
