import Dexie, { type EntityTable } from 'dexie';
import { LessonInDB } from './lesson';
import { CourseInDB } from './course';
import { ElementInDB } from './element';
import { DB_NAME } from './storage-api.constant';

export class DB extends Dexie {
  lessons!: EntityTable<LessonInDB, 'id'>;

  courses!: EntityTable<CourseInDB, 'id'>;

  elements!: EntityTable<ElementInDB, 'id'>;

  constructor() {
    super(DB_NAME);
    // NOTE: once deployed and used in production, latest and previous versions of the DB must NOT change
    // see https://dexie.org/docs/Dexie/Dexie.version() for more info
    this.version(1).stores({
      lessons: 'id',
      courses: 'id',
      elements: 'id, lessonId',
    });
  }
}

export const db = new DB();
