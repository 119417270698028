import i18n, { KeyPrefix } from 'i18next';
import {
  UseTranslationOptions,
  initReactI18next,
  useTranslation,
} from 'react-i18next';
import { localeEN } from './en';

export const resources = {
  en: localeEN,
};

export const defaultNS = 'common';

void i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources,
  interpolation: {
    escapeValue: false,
  },
  saveMissing: true,
  missingKeyHandler: (_lng, ns, key) => {
    const errorMsg = `"${key}" is missing from the "${ns}" namespace!`;
    throw new Error(errorMsg);
  },
});

i18n.services.formatter?.add('lowercase', (value: string) =>
  value.toLowerCase(),
);

export const i18next = i18n;

export const useAppTranslation = (
  options: UseTranslationOptions<KeyPrefix<['common', 'auth']>> = {},
) => useTranslation(['common', 'auth'], options);

export type TAppFunction = ReturnType<typeof useAppTranslation>['t'];
