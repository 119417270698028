import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Element } from '../../api/endpoints/element/api-element.type';
import { INormalizedState, INewObjectState } from '../state';

const initialState: INormalizedState & INewObjectState = {
  courses: {},
};

const normalizedSlice = createSlice({
  name: 'slides',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setNewSlideElement: (
      state,
      action: PayloadAction<Partial<Element> | undefined>,
    ) => {
      state.newSlideElement = action.payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { setNewSlideElement } = normalizedSlice.actions;

export const { reducer: normalizedReducer } = normalizedSlice;
