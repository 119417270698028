import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Bugsnag from '@bugsnag/browser';
import { UseLiveCookieReturn } from './use-live-cookie.type';

export const useLiveCookie = <T>(
  cookieName: string,
  parser: (value: string) => T,
): UseLiveCookieReturn<T> => {
  const [cookieValue, setCookieValue] = useState<T | undefined>(() => {
    const cookie = Cookies.get(cookieName);
    try {
      return cookie ? parser(cookie) : undefined;
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
        console.error('An error occurred:', error.message);
      } else {
        console.error('An unexpected error occurred:', error);
      }
      return undefined;
    }
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const cookie = Cookies.get(cookieName);
      const newValue = cookie ? parser(cookie) : undefined;
      if (newValue !== cookieValue) {
        setCookieValue(newValue);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [cookieName, cookieValue, parser]);

  const setCookie = (value: string) => {
    Cookies.set(cookieName, value);
    setCookieValue(parser(value));
  };

  return [cookieValue, setCookie];
};
