import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { App } from './app';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './assets/font/stylesheet.css';
import { store } from './redux/store';
import { theme } from './theme';
import { SnackbarUtilsConfigurator } from './utils/snackbar-helper';

// FIXME (478): resolve the ESLint error and remove the eslint-disable comment
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CloseSnackbarAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      style={{ marginTop: '0.1em' }}
      onClick={() => {
        // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        closeSnackbar(id);
      }}
    >
      <Close />
    </IconButton>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

if (process.env.NODE_ENV !== 'development') {
  Bugsnag.start({
    apiKey: 'e7c9810f05f170e8c148b980b5052ade',
    releaseStage: process.env.NODE_ENV,
    plugins: [new BugsnagPluginReact(React)],
    appType: 'client',
    appVersion: process.env.REACT_APP_VERSION,
  });
}

const lightTheme = createTheme({}, theme);

root.render(
  <Provider store={store}>
    <ThemeProvider theme={lightTheme}>
      <SnackbarProvider
        style={{ whiteSpace: 'nowrap', flexWrap: 'nowrap' }}
        // FIXME (484): resolve the ESLint error and remove the eslint-disable comment
        // eslint-disable-next-line react/no-unstable-nested-components
        action={(key) => <CloseSnackbarAction id={key} />}
        preventDuplicate
      >
        <SnackbarUtilsConfigurator />
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
);
