import React from 'react';
import { Backdrop } from '@mui/material';

export const InitialLoading = () => (
  <Backdrop open>
    <div id='load'>
      <div>M</div>
      <div>U</div>
      <div>I</div>
      <div>R</div>
      <div>O</div>
      <div>T</div>
      <div>I</div>
      <div>D</div>
      <div>U</div>
      <div>A</div>
    </div>
  </Backdrop>
);
