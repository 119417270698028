import Bugsnag from '@bugsnag/browser';
import { enqueueSnackbar } from '../../utils/snackbar-helper';
import { STORAGE_USE_ERROR_MESSAGE } from '../storage-api.constant';
import { LessonStorageControllers } from './storage-lesson.type';
import { lessonStorageService } from './storage-lesson.service';

export const lessonStorageControllers: LessonStorageControllers = {
  '/lessons/:lessonId/currentSlide': async ({
    params: { lessonId },
    payload: { slideId },
  }) => {
    try {
      await lessonStorageService.updateSelectedSlideId(lessonId, slideId);
      await lessonStorageService.addSeenSlideIds(lessonId, slideId);
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
      enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });
    }
  },
};
