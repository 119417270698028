import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeProviderProps } from './stripe-provider.type';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_PUBLIC_KEY || '',
);

export const StripProvider = ({ children }: StripeProviderProps) => (
  <Elements stripe={stripePromise}>{children}</Elements>
);
