import {
  PreloadedState,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { api } from '../api/api';
import { activeReducer } from './slices/active.slice';
import { normalizedReducer } from './slices/normalized.slice';
import { statusReducer } from './slices/status.slice';
import { viewsReducer } from './slices/views.slice';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  views: viewsReducer,
  store: normalizedReducer,
  active: activeReducer,
  status: statusReducer,
});

const setupStore = (
  preloadedState?: PreloadedState<ReturnType<typeof rootReducer>>,
) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware().concat(api.middleware),
    preloadedState,
  });

export const store = setupStore();

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
