import { CreateCodeSubmissionQueryArgs } from '../../api/endpoints/code-submission';
import { elementStorageService } from '../element';

export const codeSubmissionStorageService = {
  create: async ({
    payload: { isPassed, slideElementId },
  }: CreateCodeSubmissionQueryArgs) => {
    await elementStorageService.updateIsAssignmentPassed(
      slideElementId,
      isPassed,
    );
  },
};
