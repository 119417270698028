import React from 'react';
import { Box, Button, Snackbar, Typography, useTheme } from '@mui/material';

export const CookiePopUp = ({
  hasAcceptedCookies,
  setHasAcceptedCookies,
}: {
  hasAcceptedCookies: boolean;
  setHasAcceptedCookies: (state: boolean) => void;
}) => {
  const theme = useTheme();
  const onClick = () => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000 * 365); // Year in ms.
    document.cookie = `acceptedCookies=necessary;path=/;expires=${expires.toUTCString()}`;
    setHasAcceptedCookies(true);
  };

  return (
    <Snackbar
      open={!hasAcceptedCookies}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      style={{
        borderRadius: '1rem',
        marginLeft: '1.5rem', // Position is 24px from the right side by default(MUI).
        overflow: 'hidden',
        right: 0,
        marginRight: '1.5rem',
      }}
    >
      <Box
        display='grid'
        alignItems='center'
        sx={{
          padding: '1.5rem',
          backgroundColor: '#1C4647',
          gridTemplateColumns: '1fr auto',
          gap: '1rem',

          [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gap: '1.5rem',
          },
        }}
      >
        <Box>
          <Typography
            variant='h6'
            color='#FFFFFF'
            style={{ paddingBottom: '0.5rem' }}
          >
            Hello! 👋
          </Typography>
          <Typography variant='body1' color='#FFFFFF'>
            We want to inform you that our website uses only a few necessary
            cookies. We do not engage in any form of advertising or share your
            data with third parties. Use of cookies as described in our{' '}
            <a
              style={{ fontWeight: 'bold', textDecoration: 'none' }}
              // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              href={`https://${process.env.REACT_APP_WEBPAGE_HOST}/cookies-policy/`}
            >
              Cookie Policy
            </a>
            .
          </Typography>
        </Box>
        <Button
          variant='contained'
          onClick={onClick}
          style={{ color: '#1C4647', backgroundColor: '#86FFEB' }}
        >
          OK
        </Button>
      </Box>
    </Snackbar>
  );
};
