import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IStatusState } from '../state';

const initialState: IStatusState = {
  isLoading: false,
};

const statusSlice = createSlice({
  name: 'slides',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    /* eslint-enable no-param-reassign */
  },
});

export const { setLoading } = statusSlice.actions;

export const { reducer: statusReducer } = statusSlice;
